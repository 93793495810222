/*
* Date Picker Styles
*
* TODO: This needs to be updated with styles from teamsnap-ui.
* This is just a starting point so it doesn't look like garbage.
*
* Original default styles
* https://github.com/YouCanBookMe/react-datetime
*/

@import 'styles/_teamsnap.module.scss';

:global {
  .rdt {
    position: relative !important;
  }
  .rdt .form-control {
    cursor: text !important;
    height: $form-input-height !important;
    line-height: $form-input-height !important;
    padding: 0 $su-small !important;
    box-sizing: border-box !important;
    box-shadow: $inset-box-shadow-medium !important;
    background-color: $form-input-bg-color !important;
    border: $border-width-small solid $cu-divider !important;
    border-radius: $border-radius-medium !important;
    width: 100% !important;

    &.DateError {
      border-color: #d9534f !important;
    }

    &:disabled {
      background: $cu-middleground--dark !important;
      color: $ts-grey !important;
      cursor: not-allowed !important;
      &::placeholder {
        color: $ts-grey !important;
      }
    }
  }
  .rdtPicker {
    display: none !important;
    position: absolute !important;
    width: 245px !important;
    padding: 4px !important;
    margin-top: 1px !important;
    z-index: 99999 !important;
    background: #fff !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #f9f9f9 !important;
  }
  .rdtOpen .rdtPicker {
    display: block !important;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none !important;
    position: static !important;
  }

  .rdtPicker .rdtTimeToggle {
    text-align: center !important;
  }

  .rdtPicker table {
    width: 100% !important;
    margin: 0 !important;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center !important;
    height: 28px !important;
  }
  .rdtPicker td {
    cursor: pointer !important;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee !important;
    cursor: pointer !important;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999 !important;
  }
  .rdtPicker td.rdtToday {
    position: relative !important;
  }
  .rdtPicker td.rdtToday:before {
    content: '' !important;
    display: inline-block !important;
    border-left: 7px solid transparent !important;
    border-bottom: 7px solid #428bca !important;
    border-top-color: rgba(0, 0, 0, 0.2) !important;
    position: absolute !important;
    bottom: 4px !important;
    right: 4px !important;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca !important;
    color: #fff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff !important;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none !important;
    color: #999999 !important;
    cursor: not-allowed !important;
  }

  .rdtPicker td span.rdtOld {
    color: #999999 !important;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none !important;
    color: #999999 !important;
    cursor: not-allowed !important;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9 !important;
  }
  .rdtPicker .dow {
    // width: 14.2857%;
    border-bottom: none !important;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px !important;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px !important;
    vertical-align: top !important;
  }

  .rdtPrev span,
  .rdtNext span {
    display: block !important;
    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Chrome/Safari/Opera */
    -khtml-user-select: none !important; /* Konqueror */
    -moz-user-select: none !important; /* Firefox */
    -ms-user-select: none !important; /* Internet Explorer/Edge */
    user-select: none !important;
  }

  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none !important;
    color: #999999 !important;
    cursor: not-allowed !important;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer !important;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee !important;
  }

  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9 !important;
  }

  .rdtPicker button {
    border: none !important;
    background: none !important;
    cursor: pointer !important;
  }
  .rdtPicker button:hover {
    background-color: #eee !important;
  }

  .rdtPicker thead button {
    width: 100% !important;
    height: 100% !important;
  }

  td.rdtMonth,
  td.rdtYear {
    height: 50px !important;
    width: 25% !important;
    cursor: pointer !important;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee !important;
  }

  .rdtCounters {
    display: inline-block !important;
  }

  .rdtCounters > div {
    float: left !important;
  }

  .rdtCounter {
    height: 100px !important;
  }

  .rdtCounter {
    width: 40px !important;
  }

  .rdtCounterSeparator {
    line-height: 100px !important;
  }

  .rdtCounter .rdtBtn {
    height: 40% !important;
    line-height: 40px !important;
    cursor: pointer !important;
    display: block !important;

    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Chrome/Safari/Opera */
    -khtml-user-select: none !important; /* Konqueror */
    -moz-user-select: none !important; /* Firefox */
    -ms-user-select: none !important; /* Internet Explorer/Edge */
    user-select: none !important;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee !important;
  }
  .rdtCounter .rdtCount {
    height: 20% !important;
    font-size: 1.2em !important;
  }

  .rdtMilli {
    vertical-align: middle !important;
    padding-left: 8px !important;
    width: 48px !important;
  }

  .rdtMilli input {
    width: 100% !important;
    font-size: 1.2em !important;
    margin-top: 37px !important;
  }

  .DayPicker {
    display: inline-block;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;

    flex-direction: row;
    padding-bottom: 1em;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
  }

  .DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor: pointer;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  }

  .DayPicker-NavButton--next {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
  }

  .DayPicker-Caption > div {
    font-weight: bold;
    font-size: 1.15em;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    border-bottom: 1px solid rgb(212, 211, 211);
    padding: 0.5em;
    text-align: center;
    font-size: 0.875em;
    font-weight: bold;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
    border-bottom: 1px solid rgb(212, 211, 211);
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 1em;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: 0.875em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    color: #d0021b;
    font-weight: 700;
  }

  .DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    /* background-color: #eff1f1; */
  }

  /* Example modifiers */

  .DayPicker-Day--sunday {
    background-color: #f7f8f8;
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: #4a90e2;
    color: #f0f8ff;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #51a0fa;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f0f8ff;
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px !important;
  }
}
