@import 'styles/_teamsnap.module.scss';

.PlaceholderInnerContent {
  margin: 0 auto;
  text-align: center;
  width: 26%;
  padding: $su-xlarge 0;
}

.PlaceholderBody {
  font-size: $tu-large-fontSize;
  margin: $su-small auto $su-medium auto;
  text-align: left;
  padding-left: $su-large;
}
