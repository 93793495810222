// TextArea
//  -> TextArea component styles
//
// TextArea - default 100% width
// TextAreaInline - modifier to allow label inline
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

.TextAreaBase {
  height: scaleGrid(12) !important;
  line-height: auto !important;
}

.TextArea {
  composes: FieldInput from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
  composes: TextAreaBase;
  resize: none !important;
  @media (max-width: $breakpoint-sm-max) {
    font-size: $tu-large-fontSize;
  }
}

.TextAreaAligned {
  composes: FieldInputAligned from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
  composes: TextAreaBase;
  resize: none !important;
}

.TextAreaResize {
  composes: FieldInput from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
  composes: TextAreaBase;
}

.TextAreaAlignedResize {
  composes: FieldInputAligned from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
  composes: TextAreaBase;
}

.TextAreaError {
  composes: TextArea;
  border-color: $cu-negative !important;
}

.TextAreaAlignedError {
  composes: TextAreaAligned;
  border-color: $cu-negative !important;
}
