// Input
//  -> Input component styles
//
// Input - default 100% width
// InputInline - modifier to allow label inline
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

.InputIconContainer {
  composes: FieldInputIconContainer from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
}

.InputIcon {
  composes: FieldInputIcon from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
}

.Input {
  composes: FieldInput from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
  @media (max-width: $breakpoint-sm-max) {
    font-size: $tu-large-fontSize;
  }
}

.InputNote {
  display: block;
  margin: 0 0 $su-small;
}

.InputAligned {
  composes: FieldInputAligned from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
}

.InputError {
  composes: FieldInputError from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
  @media (max-width: $breakpoint-sm-max) {
    font-size: $tu-large-fontSize;
  }
}

.InputAlignedError {
  composes: FieldInputAlignedError from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
}
