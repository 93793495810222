// TableHeader
//  -> Composes table header styles for table component
//
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

.Icons {
  pointer-events: none !important;
  display: inline-block !important;
  position: relative !important;
  vertical-align: middle !important;
  padding-left: $su-xsmall !important;
}

.IconTop {
  margin-top: -$su-small !important;
  position: absolute !important;
  text-align: center !important;
  font-size: $tu-xsmall-fontSize !important;
  color: $cu-divider !important;
}

.IconBottom {
  position: absolute !important;
  margin-bottom: -$su-small !important;
  text-align: center !important;
  font-size: $tu-xsmall-fontSize !important;
  color: $cu-divider !important;
}

.IconTopActive {
  composes: IconTop;
  color: #ff8f00 !important;
}

.IconBottomActive {
  composes: IconBottom;
  color: #ff8f00 !important;
}
