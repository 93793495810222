.StatusText {
  margin-bottom: 5px;
}

.StatusSubText {
  font-weight: normal;
  margin-left: 5px;
}

.StatusLabel {
  font-weight: normal;
  margin-right: 2px;
  color: #7a7a7a;
}
