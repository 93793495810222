@import 'styles/_teamsnap.module.scss';

.GridCell {
  border-right: 1px solid $cu-divider;
  padding-top: $su-small;
  padding-bottom: $su-xsmall;
}

.GridCellLast {
  composes: GridCell;
  border: none;
}

.HeadingOverview {
  color: $cu-info;

  span {
    font-size: $tu-base-fontSize;
    font-weight: normal;
    margin-left: $su-xsmall;
  }
}
