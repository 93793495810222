@import 'styles/_teamsnap.module.scss';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $su-small;
}

.Filler {
  margin-left: auto;
  margin-right: $su-small;
  visibility: hidden;
}

.HelpIcon {
  display: inline-block;
  padding-left: $su-xsmall;
  padding-bottom: $su-xsmall;
}
