// Popover
//   -> Popover component styles
//
// TODO:
//   -> Add to TeamSnap-UI w/ modifiers and compose
//   -> Add Modifiers based off TeamSnap-UI (when available)
//   -> Add modifiers to PopoverItem (ex: negative for delete)
//
// ============================================================
@use 'sass:math';

@import 'styles/_teamsnap.module.scss';

.Popover {
  position: relative;
  display: inline-block;
}

.PopoverTrigger {
  position: relative;
  border: 1px solid $cu-divider;
  border-bottom-width: 2px;
  height: $su-large;
  width: $su-large;
  line-height: $su-medium;
  border-radius: $border-radius-medium;
  color: $cu-info--light;
  cursor: pointer;
  display: inline-block;
  font-size: $tu-base-fontSize;
}

.PopoverContainer {
  display: none;
  position: absolute;
  top: $su-large + $su-small;
  right: 0;
  transform: translateX(50%);
  margin-right: math.div($su-large, 2);
  z-index: 100;
}

.PopoverContent {
  background-color: $cu-foreground;
  border: 1px solid $cu-divider;
  border-radius: $su-small;
  margin-top: $su-xsmall;
  position: relative;
  box-shadow: $box-shadow-small;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    height: $su-xxlarge;
    width: 100%;
    bottom: 100%;
    left: 0;
    display: block;
  }

  &:after {
    height: 0;
    width: 0;
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    pointer-events: none;
    border-bottom-color: $cu-divider;
    border-width: $su-small;
    margin-left: -$su-small;
  }
}

.PopoverText {
  border-bottom: 1px solid $cu-divider--light;
  cursor: help;
}

.PopoverBody {
  padding: $su-small;
}

.PopoverList {
  list-style: none;
  padding: $su-small;
  width: scaleGrid(22);
  text-align: left;
  padding: $su-medium;
}

.PopoverItem {
  display: block;
  padding: $su-small 0;
  color: $cu-info--light;
  font-size: $tu-small-fontSize;
  border-bottom: 1px solid $cu-divider--light;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.PopoverTrigger:hover + .PopoverContainer,
.PopoverText:hover + .PopoverContainer,
.PopoverContainer:hover {
  display: block;
}
