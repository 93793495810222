// Field
//  -> Field component styles
//
// Field - default 'stacked' style
// FieldInline - modifier to place label to the left of input
// FieldLabel - Input label
// FieldError, FieldWarning - messages for field errors and warnings
//
// TODO:
//   -> Finish adding additional modifiers from teamsnapUI
//   -> 1. add margin: 0; to input-label in teamsnap-ui
//   -> 2. convert to $ts-grey when update is merged upstream
//   -> 3. add outline and width definitions to teamsnap-ui
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

.Field {
  composes: FieldGroup from '~@teamsnap/teamsnap-ui/src/css/components/FieldGroup.scss';
}

.FieldAligned {
  composes: Field;
  display: flex;
}

.FieldLabel {
  display: flex;
  composes: FieldGroup-label from '~@teamsnap/teamsnap-ui/src/css/components/FieldGroup.scss';
  margin: 0; // TD.1
}

.FieldLabel-note {
  font-weight: 400;
  margin-left: $su-small;
  color: #7a7a7a; // TD.2
}

.Field .FieldLabel {
  width: 100%;
}

.FieldAligned .FieldLabel {
  flex-basis: auto;
  padding-right: $su-xsmall;
  text-align: right;
}

.FieldInput {
  composes: Input from '~@teamsnap/teamsnap-ui/src/css/components/Input.scss';
  outline: none; // TD.3
  width: 100%; //TD.3
}

.FieldInputAligned {
  composes: FieldInput;
  flex: 1 1 0%;
  flex-basis: 0%;
}

.FieldInput:focus,
.FieldInputAligned:focus {
  border-color: highlight;
}

.FieldInputError {
  composes: FieldInput;
  border-color: $cu-negative;
}

.FieldInputAlignedError {
  composes: FieldInputAligned;
  border-color: $cu-negative;
}

.FieldMessage {
  display: block;
  font-size: 12px;
  font-style: italic;
  padding: $su-xsmall 0 0 $su-xsmall;
}

.FieldError {
  composes: FieldMessage;
  color: $cu-negative;
}

.FieldWarning {
  composes: FieldMessage;
  color: $cu-info--light;
}

.FieldInputIconContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;

  .FieldInput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus {
      border-color: highlight;
      border-left-color: $cu-divider;

      + .FieldInputIcon {
        border-color: highlight;
        border-right-color: $cu-divider;
      }
    }
  }

  .FieldInputError {
    border-left-color: $cu-divider;

    + .FieldInputIcon {
      border-color: $cu-negative;
      border-right-color: $cu-divider;
    }
  }
}

.FieldInputIcon {
  text-align: center;
  border: 1px solid $cu-divider;
  border-top-left-radius: $border-radius-medium;
  border-bottom-left-radius: $border-radius-medium;
  border-right: 0;
  padding-top: $su-xsmall;
  width: $su-xlarge;
  order: -1;
  font-size: $tu-base-fontSize;
  color: $cu-info--light;
}
