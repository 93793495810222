// TODO
//  -> Update the divider to use divider component from TeamSnap-UI
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

html {
  background-color: $cu-background;
}

.PageHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: $su-small 0;
  img {
    margin-right: $su-small;
  }
  h2 {
    padding-bottom: 0;
    // font-size: $tu-xxlarge-fontSize;
    font-size: 24px;
    height: 50px; // matches the setting for image width in html
    line-height: 50px;
    @media (min-width: $breakpoint-xs) {
      font-size: 34px;
    }
  }
}

.MobileContainer {
  composes: Panel from '../../../components/teamsnapUIExtensions/Panel/Panel.module.scss';
  margin-top: $su-small;
  padding: $su-medium;
  max-width: 750px;
  margin: 0 auto;
}

.Component {
  margin: $su-small auto;
}

.Divider {
  // 1
  border: none;
  border-top: 2px solid $cu-divider--light;
  background: transparent;
  color: transparent;
  margin: $su-small 0;
}

.InvoiceHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: $su-small;
  p {
    margin-bottom: $su-xsmall;
  }
}

.StatusHeading {
  padding-top: $su-medium;

  .Heading {
    max-width: 85%;
    word-wrap: break-word;
  }
  &-status {
    font-size: $tu-large-fontSize;
    font-weight: bold;
    color: $cu-info--light;
  }
}

.HeadingInstallments {
  display: block;
  font-weight: 500;
  font-size: 14px;
}

.InvoiceLeagueInfo {
  margin: 0;
  display: flex;
  img {
    margin-right: $su-small;
    width: scaleGrid(12);
    height: scaleGrid(12);
  }
  figcaption {
    margin-top: $su-xsmall;
  }
  h4 {
    margin: 0;
    padding-bottom: $su-xsmall;
  }
}

.Label {
  color: $cu-info--light;
  margin-right: $su-xsmall;
  padding: $su-xsmall 0;
  display: inline-block;
}

.InvoiceDescription {
  padding: $su-large 0;
}

.InvoiceFooter {
  text-align: center;
  margin-top: $su-medium;
  color: $cu-info--light;
  p {
    padding-bottom: $su-xsmall;
  }
}

.PaymentStatus {
  text-align: center;
  // margin-bottom: $su-xlarge;
  margin-bottom: 50px;
  h3 {
    font-size: $tu-xlarge-fontSize;
    @media (min-width: $breakpoint-xs) {
      font-size: $tu-xxlarge-fontSize;
    }
    color: $cu-positive;
    margin-bottom: $su-medium;
    line-height: $tu-small-lineHeight;

    .Icon-border {
      padding: 3px 5px;
      border-radius: 25px;
      vertical-align: middle;
      margin-right: $su-small;
      border: 4px solid $cu-positive;
    }
  }
  &--secondary {
    h3 {
      color: scaleColor($ts-black, 1);
      .Icon-border {
        border: 4px solid $ts-grey;
      }
      svg {
        color: $ts-grey;
      }
    }
  }
}

.Divider--dashed {
  border-top: 2px dashed scaleColor($ts-grey, 4);
}

.PaymentFooter {
  display: flex;
  justify-content: flex-end;
  padding-top: $su-xlarge;
}

.InvoiceFooter {
  margin-bottom: $su-large;
}

.FooterDivider {
  border: none;
  border-top: 2px solid $cu-divider--light;
  background: transparent;
  color: transparent;
  margin: $su-large 0;
}

.CheckoutNote {
  padding-left: 10px;
}

// Scheduled Payments
.ScheduledPayments {
  border: 1px solid #d1d1d1;
  background-color: #fdfdfd;
  border-radius: 4px;
  margin-top: 25px;
}

.ScheduledPaymentsText {
  font-weight: 600;
}

.ScheduledPaymentsInner {
  padding: 10px 15px;
}

.ScheduledPaymentsView {
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;

  a {
    font-size: 14px;
    color: scaleColor($ts-blue, 2);
  }
}

.PaymentsDisabledPanel {
  background-color: #ecf0f7;
  margin: -10px auto 10px auto;
  max-width: 750px;
  @media (orientation: landscape) {
    background-color: #ecf0f7;
  }

  @media (orientation: portrait) {
    background-color: white;
  }
  border-radius: 0px 0px 8px 8px;
  border-bottom: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
}

.PaymentsDisabledGrid {
  composes: Grid from '~@teamsnap/teamsnap-ui/src/css/components/Grid.scss';
  border-radius: 0px 0px 8px 8px;
}

.ArmsImage {
  @media (orientation: portrait) {
    max-width: 150px;
  }
}
