@import 'styles/_teamsnap.module.scss';

.ModalContainer {
  width: auto;
  max-height: 100vh; // 1
  z-index: 10;
  position: relative;
  width: scaleGrid(45);
}

.ModalHeader {
  text-align: left;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  padding: 16px;
}

.ModalFooter {
  border-top: 1px solid #ddd;
  padding: 16px;
}

.ModalBody {
  padding: 16px;
}
