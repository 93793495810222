@import 'styles/_teamsnap.module.scss';

.Panel {
  composes: Panel from '../../../components/teamsnapUIExtensions/Panel/Panel.module.scss';
}

.PanelBody {
  padding: $su-medium !important;
}

.InputControlLabel {
  composes: InputControlLabel from '../../../components/teamsnapUIExtensions/InputControl/InputControl.module.scss';
  padding-left: 0;
  margin-bottom: $su-xxsmall;
}

.GridCell {
  border-top: 1px solid $cu-divider !important;
  margin-top: $su-medium !important;
  padding-top: $su-medium !important;
}

.Field {
  margin-bottom: 0;
}

.PaymentStatus {
  margin-left: $su-xsmall;
  display: inline-block;
}

.PaymentNote {
  font-size: $tu-small-fontSize;
  font-weight: $tu-semibold-fontWeight;
  margin-top: $su-small;
  color: $cu-info--light;
}

.OverviewHeader {
  font-size: $tu-small-fontSize;
  font-weight: $tu-semibold-fontWeight;
  margin-bottom: $su-small;
}

.OverviewBody {
  margin-bottom: $su-medium;
  line-height: $tu-base-lineHeight;
  color: $cu-info--light;
}
