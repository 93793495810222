// StepNav
//  -> StepNav component styles composed from TeamsnapUI\
//
// TODO:
//   -> The SVG Icons should be added (The class StepNavStepIcon  does not support them)
//   -> The different sizing modifiers from TeamsnapUI need to be added
//   -> Double check if SVG is used in TeamSnap-UI, and update if need be
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

.StepNav {
  composes: StepNav from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
  margin: $su-medium 0;
}

.StepNavSmall {
  composes: StepNav;
  composes: StepNav--small from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
}

.StepNavTitled {
  composes: StepNav;
  composes: StepNav--titled from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
}

.StepNavTitle,
.Header {
  composes: StepNav-title from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
}

.StepNavTitleText,
.Heading {
  composes: StepNav-titleText from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
  &:before {
    background-color: $cu-primary;
  }
}

.StepNavSteps {
  composes: StepNav-steps from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
}

.StepNavStep {
  composes: StepNav-step from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
}

.StepNavStepIsEnabled {
  composes: StepNav-step is-enabled from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
  &:before {
    background-color: $cu-primary !important;
  }
}

.StepNavStepIsEnabledIsActive {
  composes: StepNav-step is-enabled is-active from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
  &:before {
    background-color: $cu-primary !important;
  }
}

.StepNavStepLink {
  composes: StepNav-stepLink from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
}

.StepNavStepTitle {
  composes: StepNav-stepTitle from '~@teamsnap/teamsnap-ui/src/css/components/StepNav.scss';
}

// Temporary Workaround to use SVG Icons with the StepNav (needs to be updated in the UI)
.StepNavStepIcon {
  // composes: StepNav-stepIcon from '_teamsnapUI/components/StepNav.scss';
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  height: 56px;
  width: 56px;
  border-radius: 28px;
  line-height: 50px;
  padding: 0;
  border: 4px solid $cu-divider;
  background: $cu-background;
}

.StepNavStepIsEnabled .StepNavStepIcon {
  border-color: $cu-primary;
  color: $cu-primary;
}

// TEMP: Remove links from navigation
// .StepNavStepIsEnabled:hover .StepNavStepIcon {
// background-color: $cu-primary;
// transition: all .35s ease-in-out;
// color: $cu-foreground;
// border-color: $cu-divider;
// }

.StepNavStepIsEnabledIsActive .StepNavStepIcon {
  color: white;
  box-shadow: inset 0 0 2px #15568c;
  border-color: $cu-divider;
  background-color: $cu-primary;
}
