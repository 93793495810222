@import 'styles/_teamsnap.module.scss';

.ModalContainer {
  max-width: scaleGrid(50);
}

.ModalHeader {
  text-align: center;
  color: $ts-red;
  border-bottom: 1px solid $cu-divider;
  padding: $su-medium;
  i {
    margin-right: $su-xsmall;
  }
}

.ModalBody {
  padding: $su-medium;
}

.ModalFooter {
  border-top: 1px solid $cu-divider;
  text-align: right;
  padding: $su-medium;
}
