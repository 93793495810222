// Table
//  -> Composes table styles by overriding some panel components from TeamsnapUI
//
// TableHeader, TableHeaderColumn
// Table, PanelBody
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

// Override PanelBody - Tables should stretch across panel
.Table {
  composes: Panel from '../../../components/teamsnapUIExtensions/Panel/Panel.module.scss';
}

.PanelHeader {
  composes: PanelHeader from '../../../components/teamsnapUIExtensions/Panel/Panel.module.scss';
  padding: 0;
}

.PanelBody {
  composes: PanelBody from '../../../components/teamsnapUIExtensions/Panel/Panel.module.scss';
  min-height: $su-large;
}

.PanelRow {
  composes: PanelRow from '../../../components/teamsnapUIExtensions/Panel/Panel.module.scss';
  padding: 0;
}

.TableGrid {
  margin: $su-small 0;
}

.Heading {
  margin-left: $su-xxsmall;
  margin-bottom: $su-xsmall;
  color: $cu-info;
}

.TableLoading {
  margin-top: $su-medium;
}
