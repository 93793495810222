@import 'styles/_teamsnap.module.scss';

.SectionHeading {
  font-size: $tu-base-fontSize !important;
  font-weight: $tu-semibold-fontWeight !important;
  font-family: $tu-openSans-fontFamily !important;
  color: $cu-info !important;
  border-bottom: $border-width-medium solid $cu-divider--light !important;
  padding: 0 0 $su-xsmall 0 !important;
  margin-bottom: $su-medium !important;
}

.SectionHeadingTitle {
  font-size: $tu-base-fontSize !important;
  font-weight: $tu-base-fontWeight !important;
  color: $cu-info !important;
  display: block !important;
  margin-bottom: 0 !important;
}
