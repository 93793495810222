.Loader {
  composes: Loader from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.LoaderInline {
  display: inline-block;
}

.LoaderIndicator {
  composes: Loader-indicator from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.LoaderText {
  composes: Loader-indicatorText from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.LoaderMessage {
  composes: Loader-message from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.LoaderJello {
  composes: Loader;
  composes: Loader--jello from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.LoaderInlineJello {
  composes: LoaderInline;
  composes: Loader--jello from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.SpinAnimation {
  composes: SpinAnimation from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.PulseAnimation {
  composes: PulseAnimation from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.PulseAnimationDot {
  composes: PulseAnimation-dot from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.JelloAnimation {
  composes: JelloAnimation from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.JelloAnimationBox {
  composes: JelloAnimation-box from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}

.JelloAnimationShadow {
  composes: JelloAnimation-shadow from '~@teamsnap/teamsnap-ui/src/css/components/Loader.scss';
}
