@import 'styles/_teamsnap.module.scss';

//------------------------------------------------------------//

// * General Widget Styles
// * 1. Panel Layout
// * 2. Text Content
// * 3. Progress Labels
// * 4. Action Required Styles

// Notes:

//------------------------------------------------------------//

// * 1. Panel Layout

.widgetPanelHeader,
.widgetPanelBody,
.widgetPanelFooter {
  width: 100%;
}

.widgetPanelHeader,
.widgetPanelFooter {
  flex: {
    grow: 0;
    shrink: 1;
    basis: auto;
  }
}

.widgetPanelBody {
  flex: {
    grow: 1;
    shrink: 1;
    basis: auto;
  }
}

.widgetPanelBody {
  padding: 0 $su-medium;
}

// * 2. Text Content Styles

.ContentHeader {
  color: $cu-info;
}

.ContentSubheading {
  color: $cu-info--light;
  padding-bottom: $su-medium;
}

.WidgetFooterInfo {
  text-align: left;
  font-style: italic;
  color: $cu-info--light;
  display: inline-block;
  padding-bottom: $su-xxsmall;
}

// * 3. Progress Labels

.Label {
  padding-bottom: $su-medium;
}

.LabelIndicatorHeader {
  color: $cu-info--light;
  font-size: $tu-base-fontSize;
  font-family: $tu-openSans-fontFamily;
  font-weight: $tu-base-fontWeight;
}

.LabelIndicator {
  display: inline-block;
  background-color: $cu-divider--light;
  width: $su-small;
  height: $su-small;
  margin-right: $su-xsmall;
  border-radius: $border-radius-small;
}

.LabelIndicator--primary {
  background-color: $cu-positive;
}

.LabelIndicator--negative,
.ActionItemStatus--negative {
  background-color: $cu-negative;
}

.LabelIndicatorContent {
  color: $cu-info;
  font-size: $tu-large-fontSize;
  font-weight: $tu-bold-fontWeight;
}

.LabelIndicatorInfo {
  color: $cu-info--light;
  font-size: $tu-small-fontSize;
  font-weight: $tu-base-fontWeight;
  margin-left: $su-xsmall;
}

.ProgressLabel {
  font-weight: $tu-base-fontWeight;
  color: $cu-info--light;
  font-size: $tu-base-fontSize;
  font-family: $tu-openSans-fontFamily;
}

.ProgressCount {
  margin-left: $su-xsmall;
  color: $cu-info;
  display: inline-block;
  font-weight: $tu-semibold-fontWeight;
  margin-right: $su-small;
}

// * 4. Action Required Styles

.ActionItemHeader {
  font-weight: normal;
}

.ActionItemInfo {
  color: $cu-info--light;
}

.ActionItemList {
  color: $cu-info--light;
  margin-bottom: $su-xsmall;
}

.ActionItemStatus {
  height: $su-small;
  width: $su-small;
  border-radius: 50%;
  display: inline-block;
  background-color: $cu-info--light;
}

.ActionItemStatus--primary {
  background-color: $cu-positive;
}

// * 5.

.RadialMods {
  margin: 0 auto !important;
}
