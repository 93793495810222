@import 'styles/_teamsnap.module.scss';

.FormGroupRight {
  border-left: 1px solid $cu-divider--light;
  padding-left: $su-medium;
}

.FieldError {
  composes: FieldError from '../../../components/teamsnapUIExtensions/Form/Field.module.scss';
}

.MemberListItem {
  color: $cu-info;
  font-size: $tu-small-fontSize;
  padding: $su-xsmall 0;
}

.MemberListName {
  font-weight: $tu-base-fontWeight;
  font-size: $tu-base-fontSize;
}

.MemberListDetails {
  color: $cu-info--light;
  font-size: $tu-small-fontSize;
}

.MemberListItem a {
  float: right;
  margin-right: $su-small;
}

.SelectedMembers {
  height: scaleGrid(80);
  max-height: caleGrid(80);
  overflow-y: auto;
}
