//
// Main
//   -> Root stylesheet for all 'global' classes
//
// Most styles are done via css-modules, but a few are still better served using
// straight CSS.  Grid for example is imported globally and used throughout the
// application.
//
// =============================================================================

// TODO: There is an 'order' issue during compilation with the ExtractTextPlugin when loading the league styles in
// the App.js file, but there is an issue including the league.css file through css modules with any animation
// key-frames, the animations do not load correctly to global scope...

// Load all teamsnapUI styles globally
:global {
  @import '~@teamsnap/teamsnap-ui/src/css/themes/league.scss';
}

// @import '~@teamsnap/teamsnap-ui/src/css/themes/league.scss';

:global {
  // Temp Placeholders to provide examples for overriding columns
  .Panel-childRows .Panel-cell--positive,
  .Panel-cell--positive {
    color: #7fc243;
  }
  .Panel-childRows .Panel-cell--negative,
  .Panel-cell--negative {
    color: #e26362;
  }
  .Panel-childRows .Panel-cell--muted,
  .Panel-cell--muted {
    color: #aaa;
  }
}

/* --------------------------------------------------------------------------
 * Grids - https://teamsnap-ui-patterns.netlify.com/patterns/components/grid.html
 * -------------------------------------------------------------------------- */
:global {
  .Grid-cell--left {
    margin-right: auto;
  }

  .Grid-cell--right {
    margin-left: auto;
  }

  .Grid--debugger .Grid-cell {
    outline: 1px dashed red;
  }

  .debugger {
    outline: 1px dashed red;
  }

  body.modal-open {
    overflow: hidden;
  }
}
