// Toggle
//  -> Toggle styles that override InputControl component styles
//
// InputControl - default - stacked controls
// InputControlInline - modifier to allow inline controls
//
// ============================================================

.InputControl {
  composes: Toggle from '~@teamsnap/teamsnap-ui/src/css/components/Toggle.scss';
  display: block;
}

.InputControlInline {
  composes: Toggle from '~@teamsnap/teamsnap-ui/src/css/components/Toggle.scss';
}

.InputControlInput {
  composes: Toggle-input from '~@teamsnap/teamsnap-ui/src/css/components/Toggle.scss';
}

.InputControlLabel {
  composes: Toggle-label from '~@teamsnap/teamsnap-ui/src/css/components/Toggle.scss';
}
