@import 'styles/_teamsnap.module.scss';

.InputControl {
  composes: InputControl from '../../../components/teamsnapUIExtensions/InputControl/InputControl.module.scss';
  margin-left: -$su-medium;
}

.ModalBody,
.ModalFooter {
  padding: $su-medium;
}

.ModalFooter {
  border-top: 1px solid $cu-divider;
}
