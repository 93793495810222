@import 'styles/_teamsnap.module.scss';

.CheckoutForm {
  // margin-bottom: $su-medium;

  &-heading {
    color: $base-font-color;
    font-size: $tu-base-fontSize;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // padding: 0 $su-small;
    margin: 0 $su-small $su-medium;
    border-bottom: $border-width-medium solid $cu-divider;
  }
}

.CheckoutError {
  color: $cu-negative;
  display: inline-block;
  padding-bottom: $su-medium;
  &:first-letter {
    text-transform: capitalize;
  }
}

.CreditCards {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: $su-xsmall;
}

.CreditCardsLabel {
  color: $cu-info--light;
  padding-right: $su-medium;
}

.FieldLabel {
  font-weight: 500;
  font-size: 14px;
  color: scaleColor($ts-black, 3);
}

.InputGroup--stripe {
  cursor: text;
  color: #383838;
  display: block;
  width: 100%;
  height: 32px;
  line-height: 30px;
  padding: 8px 8px;
  box-sizing: border-box;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-family: inherit;
  font-size: 13px;
  transition: all ease 0.2s;
  box-shadow: 1px 1px 2px rgba(56, 56, 56, 0.15) inset;
}
