@import 'styles/_teamsnap.module.scss';

.InvoiceToggle {
  display: flex;
  justify-content: flex-end;
}

.InputControlLabel {
  composes: InputControlLabel from '../../../components/teamsnapUIExtensions/InputControl/InputControl.module.scss';
  padding-left: 0;
  margin-bottom: 4px;
  display: block;
}
