@import 'styles/_teamsnap.module.scss';

.PanelRow {
  padding: $su-medium;
  margin-bottom: 0;
}

.Heading {
  composes: Heading from '../../../components/teamsnapUIExtensions/Heading/Heading.module.scss';
  margin-top: $su-xlarge;
  margin-bottom: 5px;
  font-size: 14px;
  color: $ts-black;
}
