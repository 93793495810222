// RadioButton
//  -> RadioButton styles that override InputControl component styles
//
// InputControl - default - stacked controls
// InputControlInline - modifier to allow inline controls
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

.InputControl {
  composes: InputControl from './InputControl.module.scss';
  composes: Checkbox--radio from '~@teamsnap/teamsnap-ui/src/css/components/Checkbox.scss';
}

.InputControlInline {
  composes: InputControlInline from './InputControl.module.scss';
  composes: Checkbox--radio from '~@teamsnap/teamsnap-ui/src/css/components/Checkbox.scss';
}

.InputControlLabel {
  composes: InputControlLabel from './InputControl.module.scss';
  padding-left: 0;

  &::before {
    border-radius: $su-small;
  }
}
