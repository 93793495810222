.Feedback {
  &-wrapper {
    max-width: 1300px;
    width: 100%;
    position: fixed;
    right: 1%;
    top: 16px;
    z-index: 9999;
    left: 0;
    margin: 0 auto;
  }
}
