// Form Fields
//  -> Form fields component styles
//
// TODO:
//   -> Add some default form fields layout styles
//   -> add general form styles to teamsnap-ui + use here
//
// Note: removed display block since form group heading is a Div
// and is block level by default. Also the display being set
// bleow was overriding the Grid CSS used for the CTA
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

.FormGroup {
  // margin: 4px 0;
}

.FormGroupPadded {
  margin-top: $su-medium;
}
