@import 'styles/_teamsnap.module.scss';

.ModalBody,
.ModalFooter {
  padding: $su-medium;
}

.ModalFooter {
  border-top: 1px solid $cu-divider;
}
