@import 'styles/_teamsnap.module.scss';

.Field {
  margin-bottom: $su-xsmall;
  font-weight: 400;
  display: flex;
}

.Form {
  margin-bottom: $su-large;
}
