@import 'styles/_teamsnap.module.scss';

.PanelBody {
  padding: $su-medium !important;
}

.FormGroupLeft {
  padding-right: $su-medium;
}

.TextPrimary {
  font-size: $tu-small-fontSize;
  font-weight: $tu-small-fontSize;
  margin-bottom: 0;
}

.TextSecondary {
  font-size: $tu-xsmall-fontSize;
}
