.Paginate,
.PaginateRight {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  vertical-align: middle;
}

.PaginateCounter {
  margin-right: 13px;
  font-weight: 600;
  color: #777;
}

.PaginateItem {
  background-color: #ffffff;
  display: inline-block;
  border-right: 1px solid #ccc;
  padding: 0;
  margin: 0;

  &:last-child {
    border: none;
  }

  &:hover {
    background-color: #fafafa;
  }

  a {
    font-size: 12px;
    color: #666;
    padding: 3px 9px;
    display: block;
    font-weight: 600;
  }
}

.PaginateItemIsActive {
  composes: PaginateItem;
  background-color: #fafafa;
}

.PaginateItemIsDisabled {
  composes: PaginateItem;
  pointer-events: none;

  a {
    color: #ccc;
  }
}
