// Modal
//  -> Modal component styles
//
// TODO:
//   -> Should composes from TeamsnapUI (when available)
//   -> Different sizing options are needed including FullScreen
//
// ============================================================

@import 'styles/_teamsnap.module.scss';

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.ModalOverlay {
  background: rgba($ts-black, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.ModalContainer {
  width: auto;
  max-height: 100vh; // 1
  z-index: 10;
  position: relative;
}

.ModalSmall {
  composes: Modal;
  .ModalContainer {
    width: 100%;
    max-width: 300px;
  }
}

.ModalMedium {
  composes: Modal;
  .ModalContainer {
    width: 100%;
    max-width: 750px;
  }
}

.ModalContent {
  background: $cu-foreground;
  box-shadow: $box-shadow-large;
  border-radius: $border-radius-large;
  border: $border-width-small solid $cu-divider;
}

.ModalHeader {
  text-align: left;
  font-size: $tu-medium-fontSize;
  border-bottom: 1px solid $cu-divider;
  padding: $su-medium;
}

.ModalFooter {
  border-top: 1px solid $cu-divider;
  padding: $su-medium;
}

.ModalBody {
  padding: $su-medium;
}
