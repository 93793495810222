@import 'styles/_teamsnap.module.scss';

.PaymentDetails {
  list-style: none;
  padding: 0 0 $su-medium;
  font-size: 11px;
  li {
    padding-bottom: 5px;
  }
}

.ModalBody,
.ModalFooter {
  padding: $su-medium;
}

.ModalFooter {
  border-top: 1px solid $cu-divider;
  text-align: center;
}
