@import 'styles/_teamsnap.module.scss';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $su-medium;
}

.HeadingDivider {
  color: $cu-info--light;
}

.Panel {
  composes: Panel from '../../../components/teamsnapUIExtensions/Panel/Panel.module.scss';
  min-height: scaleGrid(43) !important;
  margin-bottom: 0 !important;
}

.PanelBody {
  padding: $su-medium !important;
}

// Invoice Overview Panel
.Invoices,
.InvoicesPaid,
.InvoicesOverdue {
  color: $cu-info--light;

  span,
  a {
    font-weight: normal;
    display: inline-block;
    margin-left: 4px;
    max-width: 90%;
    vertical-align: top;
  }
}

.InvoicesPaid {
  span {
    color: $ts-green;
  }
}

.InvoicesOverdue {
  span {
    color: $ts-red;
  }
}

.InvoicesOverview {
  list-style: none;
  padding: $su-small 0;

  li {
    color: $cu-info--light;
    padding: $su-small 0;
  }

  span {
    color: $cu-info;
    display: inline-block;
    padding-left: 4px;
  }
}

.CancelFooter {
  border-top: 1px solid $ts-grey;
  padding-top: $su-medium;
  text-align: right;
}

// Invoice Transactions Panel ('dot-leaders')
.InvoiceTransaction {
  list-style: none;
  border-bottom: 1px solid scaleColor($ts-grey, 1);
  margin: 0;
  padding: 0;
  margin-bottom: $su-medium;
  padding-bottom: $su-medium;
}

.InvoiceTransactionItem {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  margin: 4px 0;
  color: scaleColor($ts-black, 3);

  &::before {
    border-bottom: 1px dotted $ts-grey;
    content: '';
    flex-grow: 1;
    order: 2;
    margin: 0 6px;
  }
}

.InvoiceTransactionAmount {
  order: 3;
}

.InvoiceBalance {
  text-align: right;
  margin: $su-small 0;
  font-weight: $tu-semibold-fontWeight;
  font-size: $tu-base-fontSize;
  color: $cu-info--light;

  span {
    color: $ts-darkblue;
    display: inline-block;
    padding-left: $su-xsmall;
  }
}

// Invoice Messages Panel
.Messages,
.MessagesBorder {
  color: scaleColor($ts-black, 3);

  span {
    color: $cu-info--light;
    display: inline-block;
    padding-left: $su-xsmall;
    font-weight: normal;
  }
}

.MessagesBorder {
  margin-bottom: $su-medium;
  padding-bottom: $su-medium;
  border-bottom: 1px solid $ts-grey;
}

.Contacts {
  padding-left: $su-medium;
}

.ContactList {
  padding: 0;
  font-size: $su-medium;
  color: $cu-info--light;
  list-style: outside;
  line-height: 1.5;
}

.ContactItem {
  font-size: 11px;
  color: $cu-info--light;
  display: inline-block;
  margin-left: -4px;
}
