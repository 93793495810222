@import 'styles/_teamsnap.module.scss';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $su-small;
}

.Heading {
  max-width: 75%;
  word-wrap: break-word;
  margin-bottom: 0;
}

.SubHeader {
  color: $cu-info--light;
  padding-bottom: $su-small;
  font-size: $tu-base-fontSize;
}

.PanelBody {
  padding: $su-medium;
  min-height: scaleGrid(28);
}

.PanelHeader {
  composes: PanelHeader from '../../../components/teamsnapUIExtensions/Panel/Panel.module.scss';
  padding: $su-small $su-medium;
}

.HeadingLight {
  color: $cu-info;
  font-size: $tu-base-fontSize;
  font-weight: $tu-semibold-fontWeight;
}

.InvoiceDescription {
  color: $cu-info--light;
  margin-bottom: 16px;
}

.HeadingLightAmount {
  composes: HeadingLight;
  margin-bottom: 0;
}

.InvoiceTableHeader {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.InvoicePaymentOptions {
  margin-top: 0;
  padding: 0;
  list-style: none;
  color: $cu-info--light;
}

.InvoicePaymentOption {
  font-size: $tu-small-fontSize;
  color: $cu-info--light;
  padding: $su-xxsmall 0;
}

.Grid--withGutter {
  margin-right: 0;
}

.InvoicePaymentOptionActive {
  composes: InvoicePaymentOption;
  color: $cu-info;
}

.InvoiceCount {
  color: $cu-info;
  display: inline-block;
  min-width: 25px;
  text-align: center;
}

.InvoiceCountSecondary {
  composes: InvoiceCount;
  color: $cu-secondary;
}

.InvoiceAmount {
  font-weight: bold;
  display: inline-block;
  font-size: $tu-large-fontSize;
  color: $cu-info;
}

.InvoiceRule {
  margin: $su-medium 0;
}

.InvoicePaymentNote {
  margin-top: $su-xlarge;
  font-size: $tu-small-fontSize;
  color: $cu-info--light;
}

.InvoiceDueDate {
  color: $cu-negative--light;
  margin-left: $su-medium;
}

.OverviewLabel,
.OverviewLabelBorder,
.OverviewLabelInline {
  color: $cu-info--light;
  font-size: $tu-base-fontSize;
  font-weight: $tu-semibold-fontWeight;
}

.OverviewLabelBorder {
  border-top: 1px solid $cu-divider;
  margin-top: 15px;
  padding-top: 15px;
}

.OverviewLabelInline {
  display: inline-block;
  padding-right: 10px;
}

.OverviewDetails {
  color: $cu-info;
  font-size: $tu-small-fontSize;
  padding-top: 5px;
}

.OverviewInlineDetails,
.OverviewInlineDetailsNegative {
  font-weight: 600;
}

.OverviewInlineDetailsNegative {
  color: $cu-negative;
}
