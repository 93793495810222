@import 'styles/_teamsnap.module.scss';

.PanelBody {
  padding: $su-medium !important;
}

.TextArea {
  composes: TextArea from '../../../components/teamsnapUIExtensions/TextArea/TextArea.module.scss';
  height: scaleGrid(25) !important;
}

.TextAreaError {
  composes: TextAreaError from '../../../components/teamsnapUIExtensions/TextArea/TextArea.module.scss';
  height: scaleGrid(25) !important;
}

.Footer {
  display: flex;
  padding-top: $su-small;
}

.Note {
  font-size: $tu-base-fontSize;
  flex: 0 0 45%;
  width: 45%;
  text-align: left;
  margin-bottom: $su-small;

  span {
    color: $ts-blue;
    display: inline-block;
    font-weight: $tu-bold-fontWeight;
    padding-right: $su-xsmall;
  }
}

.Buttons {
  flex: 0 0 55%;
  width: 55%;
}

.Label {
  font-size: $tu-small-fontSize;
  margin-bottom: 0;
  padding-right: $su-xsmall;
}

.LabelInline {
  composes: Label;
  display: inline-block;
  padding-right: $su-xsmall;
}

.Text {
  color: $cu-info--light;
  display: inline-block;
  font-size: $tu-base-fontSize;
  margin-top: $su-xsmall;
  margin-bottom: $su-medium;
  & + h4 {
    padding-top: $su-small;
  }
}

.LabelInline + .Text {
  margin-top: 0;
}

.TextSmall {
  color: $cu-info--light;
  font-size: $tu-small-fontSize;
  padding-left: $su-xsmall;
}

.DotLeader {
  list-style: none;
  border-top: $su-xsmall solid $cu-divider--light;
  margin: 0;
  padding: $su-medium 0 $su-medium 0;
}

.DotLeaderBottom {
  composes: DotLeader;
  border-top: 0;
  padding-top: 0;
  border-bottom: $su-xsmall solid $cu-divider--light;
  margin-bottom: $su-medium;
}

.DotLeaderType {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  font-size: $tu-small-fontSize;
  font-weight: $tu-semibold-fontWeight;
  margin: $su-xsmall 0;

  &::before {
    border-bottom: 1px dotted $cu-divider;
    content: '';
    flex-grow: 1;
    order: 2;
    margin: 0 $su-small;
  }
}

.DotLeaderValue {
  order: 3;
  font-weight: $tu-semibold-fontWeight;
}

.TotalLabel {
  color: $cu-info--light;
  font-size: 1$su-xsmall;
  display: inline-block;
  padding-right: $su-xsmall;
}

.TotalValue {
  font-size: 1$su-xsmall;
  color: $ts-blue;
  font-weight: $tu-bold-fontWeight;
}
